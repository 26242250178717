<template>
  <div>
    <Menu />
    <v-card-text>
      <div v-if="loggedIn">
        <input
          v-model="oMe[index]"
          v-for="(veta, index) in oMe"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('oMe', oMe)" >uložit text</v-btn>
      </div>
      <p v-else v-for="(veta, index) in oMe" v-bind:key="index" class="field">
        {{ veta }}
      </p>
      <br />

      <h2>Reference:</h2>
      <h3>Multimedia</h3>
      <div v-if="loggedIn">
        <input
          v-model="multimedia[index]"
          v-for="(veta, index) in multimedia"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('multimedia', multimedia)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in multimedia" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>

      <h3>Plakáty</h3>
      <div v-if="loggedIn">
        <input
          v-model="plakaty[index]"
          v-for="(veta, index) in plakaty"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('plakaty', plakaty)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in plakaty" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>
      
      <h3>Signmaking</h3>
      <div v-if="loggedIn">
        <input
          v-model="signmaking[index]"
          v-for="(veta, index) in signmaking"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('signmaking', signmaking)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in signmaking" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>

      <h3>Ilustrace</h3>
      <div v-if="loggedIn">
        <input
          v-model="ilustrace[index]"
          v-for="(veta, index) in ilustrace"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('ilustrace', signmaking)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in ilustrace" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>

      <h3>Ostatní</h3>
      <div v-if="loggedIn">
        <input
          v-model="ostatni[index]"
          v-for="(veta, index) in ostatni"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('ostatni', ostatni)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in ostatni" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>

      <h3>Sochy</h3>
      <div v-if="loggedIn">
        <input
          v-model="sochy[index]"
          v-for="(veta, index) in sochy"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('sochy', sochy)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in sochy" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>
      
      <h3>Výstavy</h3>
      <div v-if="loggedIn">
        <input
          v-model="vystavy[index]"
          v-for="(veta, index) in vystavy"
          v-bind:key="index"
          class="field"
        />
        {{ veta }}
        <v-text-field v-model="novaVeta">
        </v-text-field>  
        <v-btn @click="sendText('vystavy', vystavy)" >uložit text</v-btn>
      </div>
      <ul v-else v-for="(veta, index) in vystavy" v-bind:key="index" class="field">
        <li>{{ veta }}</li>
      </ul>
    </v-card-text>
  </div>
</template>
<script>
import Menu from "../components/Menu";
import axios from "axios";

export default {
  components: {
    Menu,
  },
  name: "ViceOMe",
  created() {
    this.loggedIn = this.$store.getters.getLoggedIn;
    this.readText('oMe');
    this.readText('multimedia');
    this.readText('plakaty');
    this.readText('signmaking');
    this.readText('ilustrace');
    this.readText('ostatni');
    this.readText('sochy');
    this.readText('vystavy');
  },
  data: () => ({
    loggedIn: "",
    oMe: [],
    multimedia: [],
    plakaty: [],
    signmaking: [],
    ilustrace: [],
    ostatni: [],
    sochy: [],
    vystavy: [],
    novaVeta: ''
  }),
  methods: {
    readText(fileName) {
      axios
        .post("https://evzenhanzalik.cz/readTXT.php", {
          fName: fileName 
        })
        .then((response) => {
          if (fileName == 'oMe') {this.oMe = response.data;}
          if (fileName == 'multimedia') {this.multimedia = response.data;}
          if (fileName == 'plakaty') {this.plakaty = response.data;}
          if (fileName == 'signmaking') {this.signmaking = response.data;}
          if (fileName == 'ilustrace') {this.ilustrace = response.data;}
          if (fileName == 'ostatni') {this.ostatni = response.data;}
          if (fileName == 'sochy') {this.sochy = response.data;}
          if (fileName == 'vystavy') {this.vystavy = response.data;}        
        });
    },
    
    sendText(fileName, toFile) {
      if (this.novaVeta !=''){
        toFile.push(`${this.novaVeta} \n`);
        this.novaVeta = '';
      }
      console.log(toFile);
      axios
        .post("https://evzenhanzalik.cz/writeToFile.php", {
          fileName: fileName,
          text: toFile
        })
        .then(() => {

        });
    },
  },
};
</script>

<style scoped>
.v-card__text {
  width: 800px;
}
ul {
  padding-left: 30px;
}
h2 {
  margin-bottom: 5px;
}
.field {
  width: 100%;
}

</style>
